<template>
  <div
    id="app"
    class="h-screen w-screen flex flex-col justify-center items-center font-body"
  >
    <img alt="jellycode.cloud logo" src="./assets/logo.svg" class="w-96" />
    <HelloWorld msg="Hosting Done Right" />
  </div>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "App",
  components: {
    HelloWorld,
  },
};
</script>
